import * as React from 'react'
import { styled } from '../stitches/stitches.config'

import { Seo } from '../components/Seo'
import ErrorPage from './404'

const Homepage = () => {
  return (
    <Container>
      <Seo title="Home" description={'aa'} />
      <ErrorPage />
    </Container>
  )
}

const Container = styled('main', {
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  margin: 0,
})

export default Homepage
